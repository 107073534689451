//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import vueI18n from '@/vueI18n'
// import { i18n } from '@/vueI18n';

export default {
  name: 'app-privacy-policy-page',
  data() {
    return {
      id: 'privacyPolicy',
      tab: 0,
      language: vueI18n.locale,
      value: {
        en: '',
        ar: ''
      },
    };
  },
  computed: {
    ...mapGetters({
      record: 'policies/form/record',
      findLoading: 'policies/form/findLoading',
      saveLoading: 'policies/form/saveLoading',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    languageOptions() {
      return [
        { value: 'en', label: this.i18n('common.english') },
        { value: 'ar', label: this.i18n('common.arabic') },
      ]
    },
    editorConfig() {
      return {
        language: this.language,
        extraPlugins: 'placeholder',
        // toolbar: [ [ 'Bold' ] ],
      }
    },
  },
  async mounted() {
    await this.doFind(this.id)
    this.value = this.record ? this.record.value : this.value
  },
  methods: {
    ...mapActions({
      doNew: 'policies/form/doNew',
      doFind: 'policies/form/doFind',
      doUpdate: 'policies/form/doUpdate',
      doCreate: 'policies/form/doCreate',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    async doSubmit() {
      if (!this.record) {
        await this.doCreate({ id: this.id, values: this.value })
      } else {
        await this.doUpdate({ id: this.id, values: this.value })
      }
    }
  },
  watch: {
    currentLanguageCode(newval) {
      this.language = vueI18n.locale
      this.tab = newval == 'ar' ? 1 : 0
    }
  },
};
